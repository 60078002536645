import { HiShieldCheck } from "react-icons/hi";
import { MdCancel, MdAnalytics } from "react-icons/md";
const data = [
  {
    icon: <HiShieldCheck/>,
    heading: "Customer Focus & Satisfaction",
    detail:
      "Our customers lead the business. We believe in sharing success through strong, long-term relationships with our customers, investors and partners.We work hard to exceed our customers expectations.",
  },
  {
    icon: <MdCancel />,
    heading: "Performance + Drive",
    detail:
      "We have a determination to make things happen and be the best at what we do. We’re passionate about providing the highest quality product and service for our customers, investors and capital partners.",
  },
  {
    icon: <MdAnalytics />,
    heading: "Innovative + Dynamic",
    detail:
      "We’ve always tried to be different, a different approach to business, a different approach to customer service, a different attitude, a different culture. We’ll always believe in the importance of fresh thinking.",
  },
];
export default data;