import React from 'react'; 
import "./Hero.css";
import {HiLocationMarker} from 'react-icons/hi';
import CountUp from 'react-countup';
import {motion} from 'framer-motion';

const Hero = () => {
    return (
        <section className="hero-wrapper">
            <div className="paddings innerWidth flexCenter hero-container ">
                
                {/*left side*/}
                <div className="flexColStart hero-left">
                    <div className="hero-title">
                        <div className="orange-circle"/>
                        <motion.h1
                            initial={{y: "2rem", opacity: 0}}
                            animate={{y: 0, opacity: 1}}
                            transition={{
                                duration: 2,
                                type: "spring"
                            }}
                        >

                        <h1>Discover <br />
                        Most Suitable <br /> Property</h1>
                        </motion.h1>
                        
                    </div>

                    <div className="flexColStart hero-des">
                        <span className="secondaryText">Find a varities of properties that suit you well</span>
                        <span className="secondaryText">Forget all difficulties in finding a residence for you</span>
                    </div>

                    <div className="flexCenter search-bar">
                        <HiLocationMarker color = "var(--blue)" size = {25} />
                        <input type="text"/>
                        <button className="button">search</button>
                    </div>

                    <div className="flexCenter stats">
                        <div className="flexColStart stat">
                            <span>
                                <CountUp start={8200} end={8800} duration={4}/>
                                <span>+</span>
                            </span>
                            
                            <span className="secondaryText">Premium Products</span>
                        </div>
                        &nbsp;
                            <div className="flexColStart stat">
                            <span>
                                <CountUp start={1750} end={1950} duration={4}/>
                                <span>+</span>
                            </span>
                            
                            <span className="secondaryText">Happy Customers</span>
                        </div>
                            <div className="flexColStart stat">
                            <span>
                                <CountUp end={21}/>
                                <span>+</span>
                            </span>
                            
                            <span className="secondaryText">Awards</span>
                        </div>
                    </div>
                </div>

                {/*right side */}
                <div className="flexCenter hero-right">
                    <div className="image-container">
                     <img src="./image/hero-image.png" alt="img"/>
                    </div>
                </div>

            </div>
        </section>
    )
}

export default Hero