import React from 'react';
import './Social.css';
import { AiOutlineTwitter } from 'react-icons/ai';
import { SiFacebook } from 'react-icons/si';
import { BsInstagram } from 'react-icons/bs';
import { BsGithub } from 'react-icons/bs';
import { BsLinkedin } from 'react-icons/bs';

const Social = () => {
    return (
        <section className="f-wrapper">
             <div className="paddings innerWidth flexCenter f-container">
                {/* left side */}
                <div className="flexColStart f-left">
                    <img src="./image/real.PNG" alt='' width ={120}/>

                    <span className="secondaryText">
                        Our vision is, achieving true success can only come <br/>from
                        years of client dedication and market knowledge.
                    </span>
                </div>

                {/* right side */}
                <div className="flexColStart f-right">
                    <span className="primaryText">Connect with us</span>
                    <span className="iconss secondaryText">
                
                    <a href="https://twitter.com/i_agbato" target="_blank">
                    <AiOutlineTwitter size={20}/>
                    </a>
                    &nbsp;
                    &nbsp;
                    <SiFacebook size={20}/>
                    &nbsp;
                    &nbsp;
                    <BsInstagram size={20}/>
                    &nbsp;
                    &nbsp;
                    <a href="https://github.com/Dimpl-dee" target="_blank">
                    <BsGithub size={20}/>
                    </a>
                    &nbsp;
                    &nbsp;
                    <a href="https://www.linkedin.com/in/ifadayo-agbato-a25928202/" target='_blank'>
                    <BsLinkedin size={20}/>
                    </a>
                    </span>

                    <div className="flexCenter f-menu">
                        <span>Property</span>
                        <span>Services</span>
                        <span>Products</span>
                        <span>About us</span>
                    </div>
                </div>
             </div>
        </section>
       
    )
}

export default Social