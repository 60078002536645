import Header from './components/Header/Header';
import Hero from './components/Hero/Hero';
import './App.css';
import Industry from './components/Industry/Industry';
import Resident from './components/Resident/Resident';
import Value from './components/Value/Value';
import Contact from './components/Contact/Contact';
import Start from './components/Start/Start';
import Social from './components/Social/Social';

function App() {
    return (
      <div className="App">
       <div>
       
          <Header/>
          <Hero/>
          
        </div>
        
          <Industry/>
          <Resident/>
          <Value/>
          <Contact/>
          <Start/>
          <Social/>
      </div>
    
   

    );
  }
  
  export default App;