import React from "react"
import './Industry.css'

const Industry = () =>{
    return (
        <section className="i-wrapper">
            <div className="paddings innerWidth flexCenter i-container">
                <img src="./image/prologis.png" alt=""/>
                <img src="./image/tower.png" alt=""/>
                <img src="./image/equinix.png" alt=""/>
                <img src="./image/realty.png" alt=""/>
            </div>
        </section>
    )
}

export default Industry